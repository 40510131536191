var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!!_vm.data && !(!_vm.data.isUsed && !_vm.data.isNew))?_c('preview',{attrs:{"data":_vm.data},on:{"onSave":_vm.onSave,"onCancel":_vm.onCancel}}):_vm._e(),_c('form',{staticClass:"box p-4 has-background-white",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('h3',{staticClass:"has-text-centered has-text-weight-bold w-full mb-4"},[_vm._v("Поиск и проверка документов")]),_c('ValidationProvider',{attrs:{"name":"гос.номер","rules":{ required: true, regex: /[\u0430-\u044F\u1C80-\u1C86]{1}[0-9]{3}[\u0430-\u044F\u1C80-\u1C86]{2}[0-9]{2,3}/i }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{directives:[{name:"mask",rawName:"v-mask",value:([
                    /[авекмнорстухАВЕКМНОРСТУХabekmhopctyxABEKMHOPCTYX]/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /[авекмнорстухАВЕКМНОРСТУХabekmhopctyxABEKMHOPCTYX]/,
                    /[авекмнорстухАВЕКМНОРСТУХabekmhopctyxABEKMHOPCTYX]/,
                    /\d/,
                    /\d/,
                    /\d/ ]),expression:"[\n                    /[авекмнорстухАВЕКМНОРСТУХabekmhopctyxABEKMHOPCTYX]/,\n                    /\\d/,\n                    /\\d/,\n                    /\\d/,\n                    /[авекмнорстухАВЕКМНОРСТУХabekmhopctyxABEKMHOPCTYX]/,\n                    /[авекмнорстухАВЕКМНОРСТУХabekmhopctyxABEKMHOPCTYX]/,\n                    /\\d/,\n                    /\\d/,\n                    /\\d/,\n                ]"}],attrs:{"custom-class":"is-small","label":"Введите госномер автомобиля","message":errors[0],"type":errors[0] && 'is-danger'}},[_c('b-input',{attrs:{"placeholder":"А111АА111"},model:{value:(_vm.licensePlate),callback:function ($$v) {_vm.licensePlate=$$v},expression:"licensePlate"}})],1)]}}],null,true)}),(!_vm.internalEmail)?_c('ValidationProvider',{attrs:{"name":"email","rules":{ required: true, email: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-field',{staticClass:"mt-4",attrs:{"custom-class":"is-small","label":"Введите Email","message":errors[0],"type":errors[0] && 'is-danger'}},[_c('b-input',{attrs:{"placeholder":"example@domain.ru"},model:{value:(_vm.mail),callback:function ($$v) {_vm.mail=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"mail"}})],1)]}}],null,true)}):_vm._e(),_c('b-button',{staticClass:"mt-4 has-text-weight-bold",attrs:{"loading":_vm.isLoading,"expanded":"","type":"is-primary","disabled":invalid},on:{"click":_vm.onSubmit}},[_vm._v("Поиск")])],1),(_vm.mode !== 'policy' && _vm.data && !_vm.data.isUsed && !_vm.data.isNew)?_c('div',{staticClass:"box w-full"},[_c('p',{staticClass:"mb-2"},[_vm._v(" По данным, полученным из РСА полис ОСАГО на "+_vm._s(_vm.data ? ((_vm.data.brand) + " " + (_vm.data.model)) : 'Ваш автомобиль')+" "),_c('span',{staticClass:"has-text-danger"},[_vm._v("прекратил свое действие.")])]),_c('p',[_c('a',{staticClass:"button has-text-weight-bold is-primary is-fullwidth",attrs:{"href":"https://osago.red?utm_source=iframe&is_app=true&header=false"},on:{"click":_vm.trackNewPolicy}},[_vm._v("Оформить новый")])])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }